import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

export type Props = {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
};

export const Seo: React.FC<Props> = ({
  title = null,
  description = null,
  image = null,
  article = false,
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery<GatsbyTypes.SeoQuery>(query);

  const {
    defaultTitle,
    defaultDescription,
    defaultImage,
    siteUrl,
    twitterUsername,
  } = { ...site?.siteMetadata };

  const seo = {
    title: title ? `${title} - ${defaultTitle}` : defaultTitle,
    description: description || defaultDescription,
    image: image ? `${siteUrl}${image}` : `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {/* Open Graph */}

      <meta property="og:type" content="website" />

      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.url && <meta property="og:url" content={seo.url} />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {seo.url && <meta property="twitter:url" content={seo.url} />}
    </Helmet>
  );
};

export const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultDescription: description
        defaultTitle: title
        defaultImage: image
        siteUrl: url
        twitterUsername
      }
    }
  }
`;
