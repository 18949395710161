import * as types from 'src/types';

import imgLogo from './storybookStatic/logo-600w.png';

export const brandLogo: types.ImageFields = {
  alt: 'HBO Max Insider logo',
  src: {
    sm: imgLogo,
    lg: imgLogo,
  },
};

export const headline: types.TextFields = {
  text: 'Enter your passphrase',
  style: {},
};

export const noPassphrase = {
  text: "I don't have a passphrase",
  style: {},
};

export const passphrases = [
  {
    utm_medium: 'direct',
    utm_campaign: 'altice',
    utm_source: 'altice',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=altice',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'att',
    utm_source: 'att',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=att',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'charter',
    utm_source: 'charter',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=charter',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'cox',
    utm_source: 'cox',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=cox',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'cx',
    utm_source: 'cx',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=cx',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'frontier',
    utm_source: 'frontier',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=frontier',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'hulu',
    utm_source: 'hulu',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=hulu',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'independents',
    utm_source: 'independents',
    brand_logo:
      'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=independents',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'midsize',
    utm_source: 'midsize',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=midsize',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'verizon',
    utm_source: 'verizon',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=verizon',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'xfinity',
    utm_source: 'xfinity',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=xfinity',
  },
  {
    utm_medium: 'direct',
    utm_campaign: 'spanish',
    utm_source: 'spanish',
    brand_logo: 'https://dummyimage.com/80x32/ffffff/2e2e2e.jpg&text=spanish',
  },
];
