import * as React from 'react';
import clsx from 'clsx';
import * as scss from './AuthLayout.module.scss';

export type AuthLayoutProps = {
  children?: React.ReactNode;
  className?: string;
};

export const AuthLayout = ({ children, className }: AuthLayoutProps) => {
  return <main className={clsx(scss.main, className)}>{children}</main>;
};
