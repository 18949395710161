// extracted by mini-css-extract-plugin
export var box = "Passphrase-module--box--ggbOA";
export var brandLogo = "Passphrase-module--brand-logo--d+Dad";
export var brandText = "Passphrase-module--brand-text--qqbLC";
export var form = "Passphrase-module--form--by0GY";
export var formBtn = "Passphrase-module--form-btn--PAHTA";
export var formInput = "Passphrase-module--form-input--TNNRF";
export var header = "Passphrase-module--header--suA68";
export var headline = "Passphrase-module--headline--1L6Nt";
export var msg = "Passphrase-module--msg--+4VKG";
export var msgError = "Passphrase-module--msg-error--+8KBU";
export var skip = "Passphrase-module--skip--Uw50z";
export var subheadline = "Passphrase-module--subheadline--8MymT";
export var wrapper = "Passphrase-module--wrapper--Ug6fg";