import * as React from 'react';
import { useLocation } from '@reach/router';
import qs from 'query-string';
import * as auth from 'src/utils/auth';
import { AuthLayout } from '../components/Layouts/AuthLayout/AuthLayout';
import * as types from 'src/types';
import { Seo } from 'src/components/Seo/Seo';
import { Passphrase } from 'src/components/Passphrase/Passphrase';

// demo data
import * as data from '../components/Passphrase/data';

const homeUrl = process.env.APP_URL || 'http://localhost:8000';

const emptyPass: types.PassphraseParams = {
  utm_medium: 'direct',
  utm_campaign: 'none',
  utm_source: 'none',
  brand_logo: '',
};

type Status = 'idle' | 'loading' | 'pending' | 'success' | 'fail';

const PassphrasePage = () => {
  const location = useLocation();
  const [status, setStatus] = React.useState<Status>('loading');
  const [pass, setPass] = React.useState('');
  const [error, setError] = React.useState('');

  const params = qs.parse(location.search);
  const originalUrl = (params.redirect as string) || homeUrl;
  const { query: originalUrlParams } = qs.parseUrl(originalUrl);
  const utm_source = originalUrlParams.utm_source || '';

  const handleBypass = async () => {
    let href = originalUrl;
    const campaign = data.passphrases.find(
      (item) => item.utm_campaign === originalUrlParams.utm_campaign
    );

    if (!utm_source) {
      href = qs.stringifyUrl({ url: originalUrl });
    }

    if (campaign) {
      await auth.setPassJwt({ ...campaign, utm_source: emptyPass.utm_source });
    } else {
      await auth.setPassJwt(emptyPass);
    }

    window.location.href = href;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!e.currentTarget.pass.value) {
      setError('Please enter passphrase');
      return;
    }

    setPass(e.currentTarget.pass.value || '');
  };

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.value) {
        setError('');
      } else {
        setError('Please enter passphrase');
      }
    },
    []
  );

  React.useEffect(() => {
    if (!pass) return;

    async function setAuthToken() {
      setError('');

      const campaign = data.passphrases.find((item) => {
        if (originalUrlParams.utm_campaign) {
          return item.utm_campaign === originalUrlParams.utm_campaign;
        } else {
          return item.utm_source === pass;
        }
      });

      if (!campaign || campaign?.utm_source !== pass) {
        setError('Incorrect passphrase');
        return;
      }

      const { brand_logo, ...query } = campaign;
      await auth.setPassJwt(campaign);
      window.location.href = qs.stringifyUrl({
        url: originalUrl,
        query: { ...query },
      });
    }

    setAuthToken();
  }, [originalUrl, originalUrlParams.utm_campaign, pass]);

  React.useEffect(() => {
    setStatus('idle');
  }, []);

  if (auth.isLoggedIn()) {
    window.location.href = originalUrl;
    return null;
  }

  /**
   * Skips full component rendering when waiting for authentication to finish
   * evaluating and avoid flashing unnecessary views.
   */
  if ('loading' === status) return null;

  return (
    <AuthLayout className="auth bg bg-auth">
      <Seo title="Insider" />

      <Passphrase
        error={error}
        inputValue={utm_source as string}
        onBypass={handleBypass}
        onInputChange={handleInputChange}
        onSubmit={handleSubmit}
        {...data}
      />
    </AuthLayout>
  );
};

export default PassphrasePage;
