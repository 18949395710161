import * as React from 'react';
import clsx from 'clsx';
import useView from 'src/hooks/useView';
import * as types from 'src/types';
import { Button } from '../ui/button/button';
import * as scss from './Passphrase.module.scss';
import { useCookies } from 'react-cookie';

export type Props = {
  brandLogo: types.ImageFields;
  error?: string;
  headline: types.TextFields;
  noPassphrase: types.TextFields;
  inputValue: string;
  onBypass?: React.MouseEventHandler<HTMLButtonElement>;
  onInputChange?: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
};

export const Passphrase = ({
  brandLogo,
  error,
  headline,
  noPassphrase,
  inputValue,
  onBypass,
  onInputChange,
  onSubmit,
}: Props) => {
  const view = useView();
  const [, setCookie, removeCookie] = useCookies(['firstVisit']);

  React.useEffect(() => {
    removeCookie('firstVisit');

    setCookie('firstVisit', 0, {
      path: '/',
    });
  }, [setCookie, removeCookie]);

  return (
    <section className={clsx(scss.wrapper)}>
      <div className={clsx(scss.box)}>
        <header className={clsx(scss.header)}>
          <h1 className={clsx(scss.headline)}>
            <img
              className={clsx(scss.brandLogo)}
              src={brandLogo.src[view] ?? ''}
              alt={brandLogo.alt}
            />
          </h1>

          <h2 className={clsx(scss.subheadline)} style={headline.style}>
            {headline.text}
          </h2>
        </header>

        {error && (
          <div className={clsx(scss.msg, { [scss.msgError]: !!error })}>
            {error}
          </div>
        )}

        <form
          className={clsx(scss.form)}
          autoComplete="off"
          onSubmit={onSubmit}>
          <input hidden type="hidden" name="hidden" autoComplete="false" />

          <input
            id="pass"
            className={clsx(scss.formInput)}
            type="text"
            name="pass"
            placeholder="Your Pass Phrase"
            defaultValue={inputValue}
            onChange={onInputChange}
          />

          <Button
            outline
            className={clsx(scss.formBtn)}
            tag="button"
            inputType="submit"
            variant="primary"
            mode="light"
            label="Submit"
          />
        </form>

        <button
          className={clsx(scss.skip)}
          style={noPassphrase.style}
          onClick={onBypass}>
          {noPassphrase.text}
        </button>
      </div>
    </section>
  );
};
