/**
 * Project wide custom types.
 */

import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * App breakpoints
 */

export const Breakpoints = {
  base: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1400,
  xxl: 1600,
  fhd: 1920,
} as const;

/**
 * Breakpoint union keys and values
 */
export type Breakpoint = typeof Breakpoints[keyof typeof Breakpoints];
export type BreakpointKeys = keyof typeof Breakpoints;

/**
 * Size abbreviations
 */
export const Sizes = {
  base: 'base',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  fhd: 'fhd',
} as const;

/**
 * Size union values
 */
export type Size = typeof Sizes[keyof typeof Sizes];

/**
 * Fields with different size properties/values
 */
export type SizeFields<T extends unknown> = {
  [key in Size]?: T;
};

/**
 * Button input types
 */
export const ButtonInputTypes = {
  submit: 'submit',
  reset: 'reset',
  button: 'button',
} as const;

/**
 * Button input type union
 */
export type ButtonInputType = typeof ButtonInputTypes[keyof typeof ButtonInputTypes];

/**
 * Variants
 */
export const Variants = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

/**
 * Ui variant unions
 */
export type Variant = typeof Variants[keyof typeof Variants];

/**
 * UI themes
 */
export const Themes = {
  light: 'light',
  dark: 'dark',
  seasonal: 'seasonal',
} as const;

/**
 * UI theme variant
 */
export type Theme = typeof Themes[keyof typeof Themes];

/**
 * Button type for targeting click event when using `<a>` or `<button>`
 */
export const ButtonTypes = {
  external: '_blank',
  internal: '_self',
  overlay: 'overlay',
} as const;

/**
 * Button type for click event
 */
export type ButtonType = typeof ButtonTypes[keyof typeof ButtonTypes];
export type ButtonTypeKeys = keyof typeof ButtonTypes;

/**
 * Types use for toggling state as finite state machine
 */
export const ToggleStates = {
  open: 'open',
  close: 'close',
} as const;

export type ToggleState = typeof ToggleStates[keyof typeof ToggleStates];

/**
 * Types for audio/video state
 */
export const MediaStates = {
  play: 'play',
  stop: 'stop',
  pause: 'pause',
  start: 'start',
  end: 'end',
} as const;

export type MediaState = typeof MediaStates[keyof typeof MediaStates];

/**
 * Text field properties
 */
export type TextFields = {
  text: string;
  style: React.CSSProperties;
};

/**
 * Button field properties
 */
export type ButtonFields = {
  text: string;
  link: string;
  icon?: IconProp;
  type: ButtonTypeKeys;
  overlayTitle?: string;
  style: React.CSSProperties;
  mode?: Theme;
  outline?: boolean;
};

/**
 * Video field properties
 */
export type VideoFields = {
  poster: string;
  src: {
    lg: Record<string, string> | null;
    sm: Record<string, string> | null;
  };
};

export type ImageSource = {
  url: string;
};

/**
 * Image field properties
 */
export type ImageFields = {
  alt: string;
  src: SizeFields<ImageSource>;
};

export const SlidePositions = {
  start: 'start',
  between: 'between',
  end: 'end',
} as const;

export type SlidePosition = typeof SlidePositions[keyof typeof SlidePositions];

export type PassphraseParams = {
  utm_medium: string;
  utm_campaign: string;
  utm_source: string;
  brand_logo?: string;
};

export type MarginsFields = {
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
};

export type PaddingsFields = {
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
};
