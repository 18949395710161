import * as React from 'react';
import { useWindowSize } from 'rooks';
import * as types from 'src/types';

const useView = () => {
  const { innerWidth } = useWindowSize();

  const view = React.useMemo<types.Size>(() => {
    if (!innerWidth) return types.Sizes.sm;
    return innerWidth > types.Breakpoints.lg ? types.Sizes.lg : types.Sizes.sm;
  }, [innerWidth]);

  return view;
};

export default useView;
